import React, { ReactNode } from 'react'

import * as Accordion from '@radix-ui/react-accordion'
import * as RadixTabs from '@radix-ui/react-tabs'
import clsx from 'clsx'

import { onImageError } from '@/utils/sentry/onImageError'

import { Image } from '@/components/Image'

import { Warning } from '../Warning'

type Tab = {
	label?: string
	content?: ReactNode
	image?: { url: string; dimensions: { width: number; height: number } }
	imageAlt: string
	title?: string
	text?: string
	header?: string
	priorityImage?: boolean
}

type Props = {
	className?: string
	imageHeight?: number
	alignment?: 'left' | 'center' | 'right'
	tabs: Tab[]
}

export function Tabs({ className, tabs, alignment = 'left', imageHeight = 36 }: Props) {
	if (tabs?.length === 0) return <Warning className={className}>There are no tabs</Warning>

	return (
		<div className={className}>
			<RadixTabs.Root
				className="hidden md:block w-full shadow-[0px_0px_50px_rgba(18,17,24,0.1)]"
				defaultValue="tab0"
			>
				<RadixTabs.List className="bg-white hidden md:flex gap-x-[2px] w-full">
					{tabs?.map((tab, i) => {
						return (
							<RadixTabs.Trigger
								key={i}
								value={`tab${i}`}
								className={clsx(
									{
										left: 'items-start text-left',
										right: 'items-end text-right',
										center: 'items-center text-center'
									}[alignment],
									'flex flex-col flex-1 p-8 text-bc-black bg-gray-1200 hover:bg-gray-1400 data-[state=active]:bg-white'
								)}
							>
								{tab.image && (
									<Image
										src={tab.image.url}
										alt={tab.imageAlt}
										width={(tab.image.dimensions.width / tab.image.dimensions.height) * imageHeight}
										height={imageHeight}
										className="mb-3"
										priority={tab.priorityImage}
										onError={onImageError}
									/>
								)}

								{tab.title && <p className="font-medium">{tab.title}</p>}
								{tab.text && <p className="mt-1 text-gray-600">{tab.text}</p>}
							</RadixTabs.Trigger>
						)
					})}
				</RadixTabs.List>
				{tabs?.map((tab, i) => {
					return (
						<RadixTabs.Content
							key={i}
							value={`tab${i}`}
							forceMount
							className="bg-white p-8 md:p-14 mb-0.5 md:mb-0 data-[state=inactive]:block data-[state=inactive]:md:hidden"
						>
							{tab.header && (
								<h2 className="pb-10 font-bold text-h3 border-b-2 border-bc-black mb-10">
									{tab.header}
								</h2>
							)}
							{tab.content}
						</RadixTabs.Content>
					)
				})}
			</RadixTabs.Root>

			<Accordion.Root type="multiple" className="w-full block md:hidden">
				{tabs?.map((tab, i) => {
					return (
						<Accordion.Item key={i} value={`${i}`} className="mb-0.5 bg-white">
							<Accordion.Trigger
								className={clsx(
									{
										left: 'text-left',
										right: 'text-right',
										center: 'text-center',
										justify: 'text-justify'
									}[alignment],
									'group relative w-full p-8 text-bc-black'
								)}
							>
								{tab.image && (
									<Image
										src={tab.image.url}
										alt={tab.imageAlt}
										width={tab.image.dimensions.width}
										height={
											tab.image.dimensions.width /
											(tab.image.dimensions.width / tab.image.dimensions.height)
										}
										className="mb-3 inline-flex"
										priority={tab.priorityImage}
										onError={onImageError}
									/>
								)}

								{tab.title && <p className="font-medium">{tab.title}</p>}
								{tab.text && <p className="mt-1 text-gray-600">{tab.text}</p>}

								<div className="absolute top-8 right-8 w-3 border-[6px] border-b-0 border-x-transparent border-t-current transition-transform duration-[400ms] group-data-[state=open]:rotate-180"></div>
							</Accordion.Trigger>

							<Accordion.Content className="w-full p-8 pb-14">
								{tab.header && (
									<h2 className="pb-10 font-bold text-h3 border-b-2 border-bc-black mb-10">
										{tab.header}
									</h2>
								)}
								{tab.content}
							</Accordion.Content>
						</Accordion.Item>
					)
				})}
			</Accordion.Root>
		</div>
	)
}
